import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import BannerText from "../../../components/BannerText";
import Banner from "../../../containers/Banner";
import EvolvingEnterprises from "../../../containers/EvolvingEnterprises";
import BreadCrumb from "../../../containers/BreadCrumb";
import Container from "../../../utils/Container";
import SuccessStoryCard from "../../../containers/SuccessStoryCard";
import { CardSideImg } from "../../../images/index";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "inspiration",
    link: "/inspiration",
  },
  {
    text: "success stories",
    link: "",
  },
];

const SuccessStories = ({ data }) => {
  const bannerData = data.bannerData.nodes[0];
  const allStory = data.allStory.nodes;
  const evolvingEnterprises = data.evolvingEnterprises.nodes[0];
  return (
    <Layout>
      <Seo title="Home" />
      <Banner bgImg={bannerData.desktopImage.gatsbyImageData} mobImg={bannerData.mobileImage.gatsbyImageData}>
        <div className="absolute top-[20%] lg:left-20 mx-24 sm:mx-0 2xl:left-28 md:top-48 md:left-8 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 w-full sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16 sm:-mt-6">
          <BannerText
            title={
              <h2 className="text-2xl leading-[32px] lg:text-4xl xl:leading-[52px] font-medium sm:text-normal xl:text-[44px] xl:w-80 mx-24 md:mx-0 md:text-3xl md:w-52 lg:w-60 md:leading-[38px]">
                {bannerData.title}
              </h2>
            }
            subTitle={
              <p className=" text-2xl leading-[32px] md:-mt-4 text-[#000000] xl:leading-[52px]  font-extrabold lg:text-3xl lg:w-72 md:w-48 xl:text-[44px] xl:font-extrabold mx-24 md:mx-0 xl:w-80 md:text-3xl md:leading-[38px]">
                {bannerData.subTitle}
              </p>
            }
          />
        </div>
      </Banner>

      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 0px" }} />
      </Container>
      <Container>
        <div className="w-full  pt-6 md:grid grid-cols-3 gap-4 md:w-full flex flex-col md:flex-row">
          {allStory.map((story) => {
            return (
              <SuccessStoryCard
                image={story.mainImage.gatsbyImageData}
                tag={story.tag}
                miniBodyDesc={story.miniBodyDesc}
                key={story.id}
              />
            );
          })}
        </div>
      </Container>
      <div>
        <img className="w-1/6" src={CardSideImg} alt="cardimage" />
      </div>
      <EvolvingEnterprises
        bgImg={evolvingEnterprises.desktopImage.gatsbyImageData}
        mobImg={evolvingEnterprises.mobileImage.gatsbyImageData}
        title={evolvingEnterprises.title}
        leftText={evolvingEnterprises.leftText}
        rightText={evolvingEnterprises.rightText}
        bottomText={evolvingEnterprises.bottomText}
      />
    </Layout>
  );
};
SuccessStories.propTypes = {
  data: PropTypes.object,
};
export default SuccessStories;
export const query = graphql`
  query {
    bannerData: allContentfulBannerContent(filter: { heading: { eq: "Success Stories Page" } }) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        title
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
        subTitle
      }
    }
    allStory: allContentfulSuccessStory {
      nodes {
        mainImage {
          gatsbyImageData
        }
        tag
        miniBodyDesc
        id
      }
    }
    evolvingEnterprises: allContentfulEvolvingEnterprises {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
        bottomText
        leftText
        title
        rightText
      }
    }
  }
`;
