import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { TiChevronRight } from "react-icons/ti";

const SuccessStoryCard = ({ image, tag, miniBodyDesc }) => {
  return (
    <Link to="#">
      <div className=" pb-5 w-full relative md:w-full md:mb-5 text-center md:text-left">
        <GatsbyImage className="block w-full success-card-img" image={image} alt="mainimage" />
        <div className=" absolute top-[25px] right-[30px] bg-[#ffda00] text-[#000000] font-bold text-sm items-center justify-center flex  rounded-2xl w-28 pt-1  pb-[0.35rem] 2xl:rounded-2xl">
          {tag}
        </div>
        <div className=" md:mb-5 text-base md:pb-2 text-[#333e47] font-bold xl:text-base pt-3 text-center md:text-left md:text-sm">
          {miniBodyDesc}
        </div>
        <div className=" text-sm font-bold text-[#0064BF] xl:text-sm flex items-center text-center md:text-left justify-center md:justify-start">
          know more <TiChevronRight className="font-extrabold" />
        </div>
      </div>
    </Link>
  );
};

SuccessStoryCard.propTypes = {
  image: PropTypes.string,
  tag: PropTypes.string,
  miniBodyDesc: PropTypes.string,
};

export default SuccessStoryCard;
